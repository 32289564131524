
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';

@Component({
    name: 'AppSidebar',
})
export default class AppSidebar extends Vue {
    @Prop({ default: false }) private loading!: boolean;
    @Prop({ default: false, required: false }) private isStatistics!: boolean;

    private collapsed: boolean = false;
    private width: number = this.isStatistics ? 500 : 400;
    private sidebarOffset: number = 0;

    private get sidebarRef() {
        return (this.$refs.appSidebar as any) ?? null;
    }
    private get backdropHeight() {
        return `calc(100vh - ${this.sidebarOffset}px)`;
    }

    // Calculate app sidebar height dynamically
    private mounted() {
        window.addEventListener('resize', this.calculateSidebarOffsetTop);
        this.$nextTick(() => {
            this.calculateSidebarOffsetTop();
        });
    }

    private beforeDestroy() {
        window.removeEventListener('resize', this.calculateSidebarOffsetTop);
    }

    private calculateSidebarOffsetTop() {
        if (!this.sidebarRef) {
            return;
        }

        this.sidebarOffset = this.sidebarRef.offsetTop;
    }

    get showBackdrop() {
        return !this.collapsed;
    }

    @Watch('collapsed', { immediate: true })
    private projectSidebarCollapsed() {
        if (!this.collapsed) {
            this.$nextTick(() => this.calculateSidebarOffsetTop());
        }

        if (!this.isStatistics) {
            this.$store.dispatch('generic/updateSidebarState', this.collapsed);
        }
    }
}
